// react
import React from 'react';

import {useHistory} from "react-router-dom"
// application
import { Search20Svg } from '../../svg';
import classNames from 'classnames';


function Search() {
    let history = useHistory();

    function handleKeyDown (event) {
        if (event.key === 'Enter') {
            event.preventDefault();
          history.push(`/search?query=${event.target.value}`);
          window.location.reload();
        }
    }
    const searchInputClasses = classNames({
        'search__input_fr': true,
    },'search__input');
    return (
        <div className="search" style={{width:"40vw"}}>
            <form className="search__form"  onKeyDown={handleKeyDown}>
                <input
                    className="search__input"
                    name="q"
                    placeholder="Search over 10,000 products"
                    aria-label="Site search"
                    autoComplete="off"
                    id="search"
                />
                <button type="button" className="search__button" >
                    <Search20Svg />
                </button>
                <div className="search__border" />
            </form>
        </div>
    );
}

export default Search;
